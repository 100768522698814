.card {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 40px;
    width: 300px;
    height: 180px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    text-align: left;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: box-shadow 0.3s ease; 
    margin: 20px;
  }
  
  .card:hover {
    box-shadow: 0px 6px 12px rgba(9, 44, 142, 0.6); 
  }
  
  .card h2 {
    margin-top: 10px; 
    font-weight: 700;
    font-size: 20px;
  }

  .card p{
    color: cadetblue;
    font-size: 17px;
    font-weight: 600;
  }